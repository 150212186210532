<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :search="search"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neues Produkt anlegen</span>
    </v-tooltip>

    <AreYouSureDialog
      title="Soll das Produkt gelöscht werden?"
      text="Wenn du das Produkt tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
export default {
  name: "index",

  components: {
    PageHeader,
    DataTable,
    AreYouSureDialog,
  },

  data() {
    return {
      pageTitle: "Produkte",
      pageDescription: `<p>Hier werden die Produkte angelegt und gepflegt, die im Shop bestellt und im JMP ausgewählt werden können.</p>`,
      title: "",
      preloader: false,
      items: [],
      headers: [],
      edit: true,
      trash: true,
      preview: true,
      copy: false,
      search: "",
      dialog: false,
    };
  },

  created: function() {
    this.getData();
  },

  methods: {
    async deleteItem() {
      /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */

      let response = await this.deleteRequest("products/" + this.currentItemId); // await data from mixin

      if (response.status === 200) {
        this.items.splice(this.currentItemIndex, 1); // remove from array
      }
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest("products"); // await data from mixin

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0

      this.$router.push({
        name: "sales.products.:id",
        params: { id: newItem.id },
      });
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */

        this.currentItemId = value.itemId;
        this.currentItemIndex = value.index;
        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.$router.push({
          name: "sales.products.:id",
          params: { id: value.itemId },
        });
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("products"); // await data from mixin
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Aktiv", value: "isActive" },
          { text: "Typ", value: "productType" },
          { text: "Name", value: "name" },
          { text: "Branche", value: "branches" },
          { text: "Im Jahresplan verfügbar", value: "isJmp" },
          { text: "Demo-Anfrage", value: "isDemoRequestable" },
          { text: "Preis", value: "price" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data.reverse();
      }
    },
  },

  mixins: [apiRequest],
};
</script>
